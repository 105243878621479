<template>
  <div>

    {{
      row.info.type
        ? types[row.info.type]
        : ''
    }}

  </div>
</template>

<script>

export default {
  name: "type-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {
      types: {
        1: 'КПЭ',
        2: 'ПО',
      }
    }
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style>
</style>